<template>
  <div>
    <SmartBreadcrumbs
      :class="{ 'mt-5': breadcrumbsPaths.length > 0 }"
      :paths="breadcrumbsPaths"
    />
    <br />

    <list />
  </div>
</template>

<script>
// Components
import TrendingCategory from "@/views/retailer/list-of-products/TrendingCategory.vue";
import list from "@/views/retailer/list-of-products/list.vue";
// Services
import HomePage from "@/services/HomePage";
import Littlebanner from "@/views/retailer/list-of-products/Littlebanner.vue";
import SmartBreadcrumbs from "@/components/retailer/SmartBreadcrumbs.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Retailerhome",
  components: {
    TrendingCategory,
    Littlebanner,
    HomePage,
    list,
    SmartBreadcrumbs,
  },
  data() {
    return {
      products: [],
      categories: [],
      banners: [],
      productSections: [],
      userType: "retailer", // TODO: Get the user type from user object
      showBanner: true,
      close: true,
    };
  },
  mounted() {
    // const retailer = JSON.parse(JSON.stringify(this.$store.state.retailer));
    this.setBreadcrumbs();
    this.getPageData();
    this.generateBreadcrumbs();
  },
  computed: {
    ...mapGetters({
      breadcrumbsPaths: "retailer/Breadcrumbs",
    }),
  },
  methods: {
    ...mapActions(["retailer/updateBreadcrumbs"]),

    generateBreadcrumbs() {
      if (this.$route.name === "Retailer Home") {
        this.setBreadcrumbs([]);
      } else if (
        this.$route.name === "ShopifyRetailerProductsCategories" &&
        !this.$route.query.search
      ) {
        this.setBreadcrumbs([
          {
            title: "List of products",
            href: "ShopifyRetailerFindProducts",
          },
          {
            title: "All categories",
            href: undefined,
          },
        ]);
      } else if (
        this.$route.name === "ShopifyRetailerProductsCategories" &&
        this.$route.query.search
      ) {
        this.setBreadcrumbs([
          {
            title: "List of products",
            href: "ShopifyRetailerFindProducts",
          },
          {
            title: `Search for "${this.$route.query.search}"`,
            href: undefined,
          },
        ]);
      }
    },
    getPageData() {
      // let data = HomePage.getHomePageData();
      HomePage.getHomePageData()
        .then((response) => {
          this.categories = response.data.categories;
          this.banners = response.data.banners;
          this.productSections = response.data.sections;
        })
        .catch((e) => {
          console.log(e.message);
          this.noConnectedShop = true;
        });
      // this.categories = data.categories;
      // this.banners=data.banners;
      // this.productSections=data.productSections;
    },
    setBreadcrumbs(paths) {
      this["retailer/updateBreadcrumbs"](paths);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.no-result {
  color: green;
  padding-top: 250px;
  padding-bottom: 250px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.products-list-wrapper {
  //padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 312px);
  grid-gap: 24px;
  justify-content: start;
  align-items: stretch;
  // div {
  //   margin-right: 10px;
  // }
}

.fake_select_wrapper {
  width: 257px !important;
}

.list_banner {
  width: calc(100% - 100px);
  margin-top: 16px;
  margin-left: 84px;

  &.isOpen {
    padding-left: 75px;
  }
}

.closeBanner {
  position: absolute;
  top: 32px;
  left: 100px;
  background: #008f4a;
  color: white;
  border: 1px solid #008f4a;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 6px;
  gap: 8px;
}
.productList__header {
  // background-color: red;
  // padding-left: 275px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
  }
  &__listCount {
    margin: 0;
    color: #667085;
    font-size: 1.3rem;
  }
}
.popular_categories {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}

.banner-border {
  border-bottom: 1px solid #e4e7ec;
  margin-top: 24px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  @media (max-width: 769px) {
    justify-content: space-between;
    column-gap: 15px;
  }
  @media (max-width: 550px) {
    justify-content: center;
  }
}

.flex-container > div {
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  margin-bottom: 15px;

  @media (max-width: 769px) {
    width: 48%;
  }
  @media (max-width: 550px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.flex-container-banners {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.flex-container-banners > div {
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  flex: 6 0 12%;

  @media (max-width: 769px) {
    flex: 6 0 40%;
  }
}
</style>
